import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Commercial Jazz Babies";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το μάθημα αυτό απευθύνεται στους μικρούς μας φίλους και φίλες και έχει
          σκοπό να απασχολήσει δημιουργικά και να διασκεδάσει τα παιδιά μέσα από
          χορογραφίες «funkyjazz» αλλά και μέσα από παιχνίδια ρυθμού, φαντασίας,
          δημιουργικότητας, αυτοσχεδιασμού και συνεργασίας. Η ελεύθερη ροή και η
          ποικιλία κινήσεων και δυναμικών της φύσης του συγκεκριμένου είδους
          βοηθά τα παιδιά να ξεδιπλώσουν το ταλέντο, τη φαντασία και την
          προσωπικότητά τους, παρέχοντας, επίσης, μια καλή ευκαιρία να μάθουν να
          συνεργάζονται δημιουργικά και αρμονικά με τους συμμαθητές τους, να
          εξελίσσονται παρέα και να αποκτούν αυτοέλεγχο και ενσυναίσθηση, στην
          πρώτη αυτή μικρή χορευτική κοινότητα.
        </P>
      </Section.Main>
    </Layout>
  );
}
